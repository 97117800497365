export default function () {
  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }
  const toSystemFriendlyString = (s) => {
    return s
      .replace(/[^a-z0-9_]+/gi, '-')
      .replace(/^-|-$/g, '')
      .toLowerCase()
  }
  const getFileExtensionFromLink = (link) => {
    return link.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
  }
  // eslint-disable-next-line complexity
  const getPflegeColornameFromHex = (hex) => {
    switch (hex) {
      case '#ffffff':
      case '#fff':
      case '#ffff':
      case '#ffffffff':
        return 'white'
      case '#f1f7f7':
      case '#f1f7f7ff':
        return 'mint-lightest'
      case '#f2f2f2':
      case '#0000000d':
        return 'neutral-5'
      default:
        return null
    }
  }

  return {
    isNumeric,
    toSystemFriendlyString,
    getFileExtensionFromLink,
    getPflegeColornameFromHex
  }
}
